html {
  scrollbar-gutter: stable;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: 0;
}

.container {
  padding-left: calc(100vw - 100%);
}

h1 {
  color: #007bff;
  margin-bottom: 1.5rem;
}

h2 {
  color: #495057;
  margin-bottom: 1rem;
}

h3 {
  color: #495057;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

h4 {
  color: #007bff;
  font-size: 1.25rem;
}